import type { ApiResponse, Language, Setting } from '~/types/Global'

export const useGlobalApi = () => {
  const runtimeConfig = useRuntimeConfig()

  const getSettings = (): Promise<ApiResponse<Setting>> => {
    return $fetch(`${runtimeConfig.public.apiBaseUrl}/api/settings`)
  }

  const getLanguages = (): Promise<ApiResponse<Language[]>> => {
    return $fetch(`${runtimeConfig.public.apiBaseUrl}/api/languages`)
  }

  return {
    getSettings,
    getLanguages
  }
}
